@import "https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;600&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Manrope, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline:  auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto -webkit-focus-ring-color;
}

a {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(90 133 150 / var(--tw-text-opacity) );
  outline-offset: 2px;
  outline: 2px solid #0000;
  font-weight: 300;
  transition-duration: .15s;
}

a:hover {
  --tw-text-opacity: 1;
  color: rgb(126 159 172 / var(--tw-text-opacity) );
  text-underline-offset: 2px;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

a:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

a:active {
  --tw-text-opacity: 1;
  color: rgb(53 105 125 / var(--tw-text-opacity) );
}

a:disabled {
  --tw-text-opacity: 1;
  color: rgb(207 219 224 / var(--tw-text-opacity) );
}

a.cta {
  --tw-text-opacity: 1;
  color: rgb(192 65 132 / var(--tw-text-opacity) );
  outline-offset: 2px;
  outline: 2px solid #0000;
}

a.cta:hover {
  --tw-text-opacity: 1;
  color: rgb(218 111 168 / var(--tw-text-opacity) );
}

a.cta:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

a.cta:active {
  --tw-text-opacity: 1;
  color: rgb(157 19 92 / var(--tw-text-opacity) );
}

a.cta:disabled {
  --tw-text-opacity: 1;
  color: rgb(229 179 205 / var(--tw-text-opacity) );
}

.button, .button[type="button"], .button[type="submit"] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 241 243 / var(--tw-bg-opacity) );
  outline-offset: 2px;
  border-width: 0;
  border-radius: .25rem;
  outline: 2px solid #0000;
  justify-content: center;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
  transition-duration: .15s;
}

.button:hover, .button[type="button"]:hover, .button[type="submit"]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 250 / var(--tw-bg-opacity) );
}

.button:focus, .button[type="button"]:focus, .button[type="submit"]:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.button:active, .button[type="button"]:active, .button[type="submit"]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(218 222 224 / var(--tw-bg-opacity) );
}

.button:disabled, .button[type="button"]:disabled, .button[type="submit"]:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.button-no-bg-color {
  outline-offset: 2px;
  border-width: 0;
  border-radius: .25rem;
  outline: 2px solid #0000;
  justify-content: center;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
  transition-duration: .15s;
}

.button-no-bg-color:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.button-no-bg-color:disabled {
  cursor: not-allowed;
  opacity: .5;
}

th, td {
  padding-bottom: 1rem;
  padding-right: 1rem;
  font-weight: 300;
}

thead {
  text-align: left;
  text-transform: uppercase;
}

label {
  text-transform: capitalize;
  --tw-text-opacity: 1;
  color: rgb(126 159 172 / var(--tw-text-opacity) );
  outline-offset: 2px;
  outline: 2px solid #0000;
  font-weight: 300;
  display: block;
}

label:focus, input, a, button, input:focus, a:focus, button:focus, input:active, a:active, button:active {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="file"], [type="week"], [multiple], textarea, select {
  width: 18rem;
  --tw-border-opacity: 1;
  border-width: 0 0 1px;
  border-color: rgb(20 53 69 / var(--tw-border-opacity) );
  outline-offset: 2px;
  outline: 2px solid #0000;
  padding: .5rem;
  font-weight: 300;
  display: block;
}

[type="text"]::placeholder, [type="email"]::placeholder, [type="url"]::placeholder, [type="password"]::placeholder, [type="number"]::placeholder, [type="date"]::placeholder, [type="datetime-local"]::placeholder, [type="month"]::placeholder, [type="search"]::placeholder, [type="tel"]::placeholder, [type="time"]::placeholder, [type="file"]::placeholder, [type="week"]::placeholder, [multiple]::placeholder, textarea::placeholder, select::placeholder {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="file"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  --tw-border-opacity: 1;
  border-color: rgb(53 105 125 / var(--tw-border-opacity) );
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

[type="text"]:disabled, [type="email"]:disabled, [type="url"]:disabled, [type="password"]:disabled, [type="number"]:disabled, [type="date"]:disabled, [type="datetime-local"]:disabled, [type="month"]:disabled, [type="search"]:disabled, [type="tel"]:disabled, [type="time"]:disabled, [type="file"]:disabled, [type="week"]:disabled, [multiple]:disabled, textarea:disabled, select:disabled {
  cursor: not-allowed;
  opacity: .5;
}

[type="checkbox"] {
  --tw-text-opacity: 1;
  color: rgb(53 105 125 / var(--tw-text-opacity) );
  outline-offset: 2px;
  border-radius: .125rem;
  outline: 2px solid #0000;
}

[type="checkbox"]:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

[type="radio"] {
  --tw-text-opacity: 1;
  color: rgb(53 105 125 / var(--tw-text-opacity) );
  outline-offset: 2px;
  border-radius: .125rem;
  outline: 2px solid #0000;
}

[type="radio"]:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

select {
  outline-offset: 2px;
  outline: 2px solid #0000;
  padding-left: .25rem;
}

select:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.menu-item {
  border-radius: .375rem;
}

.menu-item-active {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

button.cta, button.cta[type="button"], button.cta[type="submit"] {
  --tw-bg-opacity: 1;
  background-color: rgb(192 65 132 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  outline-offset: 2px;
  outline: 2px solid #0000;
}

button.cta:hover, button.cta[type="button"]:hover, button.cta[type="submit"]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(218 111 168 / var(--tw-bg-opacity) );
}

button.cta:focus, button.cta[type="button"]:focus, button.cta[type="submit"]:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

button.cta:active, button.cta[type="button"]:active, button.cta[type="submit"]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(157 19 92 / var(--tw-bg-opacity) );
}

button.cta:disabled, button.cta[type="button"]:disabled, button.cta[type="submit"]:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(229 179 205 / var(--tw-bg-opacity) );
}

.react-calendar {
  width: 24rem;
  text-align: center;
}

.react-calendar button {
  margin: .25rem;
}

.react-calendar .react-calendar__month-view__weekdays {
  visibility: hidden;
}

.react-calendar .react-calendar__tile--now {
  --tw-bg-opacity: 1;
  background-color: rgb(192 65 132 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

input + label, select + label {
  position: absolute;
  top: .5rem;
  left: .5rem;
}

select:focus-within ~ label, select:not(:placeholder-shown) ~ label, input:focus-within ~ label, input:not(:placeholder-shown) ~ label {
  z-index: 0;
  transform-origin: 0 100%;
  --tw-translate-y: -1.5rem;
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  padding-top: 0;
  padding-bottom: 0;
}

.bg-sidebar {
  --tw-bg-opacity: 1;
  background-color: rgb(20 53 69 / var(--tw-bg-opacity) );
}

.menu-item {
  --tw-text-opacity: 1;
  color: rgb(90 133 150 / var(--tw-text-opacity) );
}

.menu-item-active {
  --tw-text-opacity: 1;
  color: rgb(20 53 69 / var(--tw-text-opacity) );
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[1rem\] {
  bottom: 1rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.-right-2 {
  right: -.5rem;
}

.-top-2 {
  top: -.5rem;
}

.left-1\/2 {
  left: 50%;
}

.top-8 {
  top: 2rem;
}

.right-4 {
  right: 1rem;
}

.top-7 {
  top: 1.75rem;
}

.top-11 {
  top: 2.75rem;
}

.right-11 {
  right: 2.75rem;
}

.top-5 {
  top: 1.25rem;
}

.top-0 {
  top: 0;
}

.left-24 {
  left: 6rem;
}

.top-44 {
  top: 11rem;
}

.left-32 {
  left: 8rem;
}

.right-8 {
  right: 2rem;
}

.-top-14 {
  top: -3.5rem;
}

.top-4 {
  top: 1rem;
}

.left-80 {
  left: 20rem;
}

.bottom-4 {
  bottom: 1rem;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.z-30 {
  z-index: 30;
}

.z-0 {
  z-index: 0;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.m-0 {
  margin: 0;
}

.m-auto {
  margin: auto;
}

.m-5 {
  margin: 1.25rem;
}

.m-8 {
  margin: 2rem;
}

.m-1 {
  margin: .25rem;
}

.m-2 {
  margin: .5rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-2 {
  margin-top: .5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-3\.5 {
  margin-left: .875rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mt-\[1px\] {
  margin-top: 1px;
}

.mt-48 {
  margin-top: 12rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-20 {
  margin-top: 5rem;
}

.-mb-3 {
  margin-bottom: -.75rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-20 {
  margin-right: 5rem;
}

.ml-\[-7px\] {
  margin-left: -7px;
}

.-mt-1 {
  margin-top: -.25rem;
}

.ml-\[-14px\] {
  margin-left: -14px;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-8 {
  margin-left: 2rem;
}

.-mt-14 {
  margin-top: -3.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.ml-80 {
  margin-left: 20rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mt-16 {
  margin-top: 4rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-6 {
  height: 1.5rem;
}

.h-10 {
  height: 2.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-full {
  height: 100%;
}

.h-7 {
  height: 1.75rem;
}

.h-1 {
  height: .25rem;
}

.h-14 {
  height: 3.5rem;
}

.h-screen {
  height: 100vh;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-4 {
  height: 1rem;
}

.h-12 {
  height: 3rem;
}

.h-3 {
  height: .75rem;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[60px\] {
  height: 60px;
}

.h-44 {
  height: 11rem;
}

.h-8 {
  height: 2rem;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[88px\] {
  height: 88px;
}

.h-\[72px\] {
  height: 72px;
}

.h-28 {
  height: 7rem;
}

.h-24 {
  height: 6rem;
}

.h-96 {
  height: 24rem;
}

.h-\[152px\] {
  height: 152px;
}

.h-\[237px\] {
  height: 237px;
}

.h-auto {
  height: auto;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-\[500px\] {
  max-height: 500px;
}

.min-h-0 {
  min-height: 0;
}

.min-h-screen {
  min-height: 100vh;
}

.w-6 {
  width: 1.5rem;
}

.w-full {
  width: 100%;
}

.w-10 {
  width: 2.5rem;
}

.w-20 {
  width: 5rem;
}

.w-5 {
  width: 1.25rem;
}

.w-screen {
  width: 100vw;
}

.w-\[575px\] {
  width: 575px;
}

.w-\[80\%\] {
  width: 80%;
}

.w-11 {
  width: 2.75rem;
}

.w-\[80vw\] {
  width: 80vw;
}

.w-16 {
  width: 4rem;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-32 {
  width: 8rem;
}

.w-28 {
  width: 7rem;
}

.w-40 {
  width: 10rem;
}

.w-80 {
  width: 20rem;
}

.w-4 {
  width: 1rem;
}

.w-max {
  width: max-content;
}

.w-12 {
  width: 3rem;
}

.w-3\/4 {
  width: 75%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-14 {
  width: 3.5rem;
}

.w-3 {
  width: .75rem;
}

.w-\[24px\] {
  width: 24px;
}

.w-\[337px\] {
  width: 337px;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[28px\] {
  width: 28px;
}

.w-44 {
  width: 11rem;
}

.w-\[50px\] {
  width: 50px;
}

.w-8 {
  width: 2rem;
}

.w-64 {
  width: 16rem;
}

.w-\[85\%\] {
  width: 85%;
}

.w-56 {
  width: 14rem;
}

.w-1\/2 {
  width: 50%;
}

.w-7 {
  width: 1.75rem;
}

.w-\[312px\] {
  width: 312px;
}

.w-\[240px\] {
  width: 240px;
}

.w-\[95\%\] {
  width: 95%;
}

.w-\[43\%\] {
  width: 43%;
}

.w-\[40\%\] {
  width: 40%;
}

.w-\[17\%\] {
  width: 17%;
}

.w-3\/5 {
  width: 60%;
}

.w-1\/5 {
  width: 20%;
}

.w-24 {
  width: 6rem;
}

.w-2\/5 {
  width: 40%;
}

.w-\[120px\] {
  width: 120px;
}

.w-60 {
  width: 15rem;
}

.w-36 {
  width: 9rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-48 {
  width: 12rem;
}

.w-1\/3 {
  width: 33.3333%;
}

.min-w-max {
  min-width: max-content;
}

.min-w-\[150px\] {
  min-width: 150px;
}

.min-w-\[257px\] {
  min-width: 257px;
}

.min-w-\[128px\] {
  min-width: 128px;
}

.min-w-\[276px\] {
  min-width: 276px;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-\[600px\] {
  max-width: 600px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-\[43\%\] {
  max-width: 43%;
}

.max-w-\[20\%\] {
  max-width: 20%;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.flex-1 {
  flex: 1;
}

.flex-initial {
  flex: 0 auto;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.translate-y-48 {
  --tw-translate-y: 12rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.scale-\[200\%\] {
  --tw-scale-x: 200%;
  --tw-scale-y: 200%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  appearance: none;
}

.columns-2 {
  columns: 2;
}

.break-inside-avoid {
  break-inside: avoid;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-content-center {
  place-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse) );
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse) );
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded {
  border-radius: .25rem;
}

.\!rounded-none {
  border-radius: 0 !important;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-b-lg {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-b-none {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-0 {
  border-width: 0;
}

.border-b {
  border-bottom-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-primary-400 {
  --tw-border-opacity: 1;
  border-color: rgb(126 159 172 / var(--tw-border-opacity) );
}

.border-transparent {
  border-color: #0000;
}

.border-primary-100, .border-black {
  --tw-border-opacity: 1;
  border-color: rgb(20 53 69 / var(--tw-border-opacity) );
}

.border-\[\#CFDBE0\] {
  --tw-border-opacity: 1;
  border-color: rgb(207 219 224 / var(--tw-border-opacity) );
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.border-primary-200 {
  --tw-border-opacity: 1;
  border-color: rgb(53 105 125 / var(--tw-border-opacity) );
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity) );
}

.border-primary-500 {
  --tw-border-opacity: 1;
  border-color: rgb(207 219 224 / var(--tw-border-opacity) );
}

.border-\[\#35697D\] {
  --tw-border-opacity: 1;
  border-color: rgb(53 105 125 / var(--tw-border-opacity) );
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity) );
}

.border-\[\#d9c3c1\] {
  --tw-border-opacity: 1;
  border-color: rgb(217 195 193 / var(--tw-border-opacity) );
}

.border-error {
  --tw-border-opacity: 1;
  border-color: rgb(234 65 96 / var(--tw-border-opacity) );
}

.border-\[\#f5eeed\] {
  --tw-border-opacity: 1;
  border-color: rgb(245 238 237 / var(--tw-border-opacity) );
}

.border-cta-200 {
  --tw-border-opacity: 1;
  border-color: rgb(192 65 132 / var(--tw-border-opacity) );
}

.border-slate-400 {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity) );
}

.border-l-pink-700 {
  --tw-border-opacity: 1;
  border-left-color: rgb(190 24 93 / var(--tw-border-opacity) );
}

.border-b-primary-500 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(207 219 224 / var(--tw-border-opacity) );
}

.bg-grey-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(218 222 224 / var(--tw-bg-opacity) );
}

.bg-primary-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(90 133 150 / var(--tw-bg-opacity) );
}

.bg-teal-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 167 173 / var(--tw-bg-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-grey-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 241 243 / var(--tw-bg-opacity) );
}

.bg-primary-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 159 172 / var(--tw-bg-opacity) );
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity) );
}

.bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(0 132 137 / var(--tw-bg-opacity) );
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity) );
}

.bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 53 69 / var(--tw-bg-opacity) );
}

.bg-cta-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 65 132 / var(--tw-bg-opacity) );
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity) );
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(20 53 69 / var(--tw-bg-opacity) );
}

.bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(53 105 125 / var(--tw-bg-opacity) );
}

.bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 219 224 / var(--tw-bg-opacity) );
}

.bg-grey-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 250 / var(--tw-bg-opacity) );
}

.bg-cta-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(157 19 92 / var(--tw-bg-opacity) );
}

.bg-cta-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(218 111 168 / var(--tw-bg-opacity) );
}

.bg-cta-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 179 205 / var(--tw-bg-opacity) );
}

.bg-error {
  --tw-bg-opacity: 1;
  background-color: rgb(234 65 96 / var(--tw-bg-opacity) );
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.bg-transparent {
  background-color: #0000;
}

.bg-\[\#C04184\] {
  --tw-bg-opacity: 1;
  background-color: rgb(192 65 132 / var(--tw-bg-opacity) );
}

.bg-\[\#00a7ad\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 167 173 / var(--tw-bg-opacity) );
}

.bg-\[\#CFDBE0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(207 219 224 / var(--tw-bg-opacity) );
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity) );
}

.bg-\[\#ecf1f3\] {
  --tw-bg-opacity: 1;
  background-color: rgb(236 241 243 / var(--tw-bg-opacity) );
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity) );
}

.bg-\[\#1435452B\] {
  background-color: #1435452b;
}

.bg-\[\#DAE7F0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(218 231 240 / var(--tw-bg-opacity) );
}

.bg-\[\#FCF8E5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 248 229 / var(--tw-bg-opacity) );
}

.bg-\[\#F1E6DD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 230 221 / var(--tw-bg-opacity) );
}

.bg-\[\#F7EEED\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 238 237 / var(--tw-bg-opacity) );
}

.bg-\[\#D6EBEC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(214 235 236 / var(--tw-bg-opacity) );
}

.bg-\[\#F4E5FB\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 229 251 / var(--tw-bg-opacity) );
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity) );
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 132 137 / var(--tw-bg-opacity) );
}

.bg-\[\#f5eeed\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 238 237 / var(--tw-bg-opacity) );
}

.bg-\[\#FFFFFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-\[\#F1F1F1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241 / var(--tw-bg-opacity) );
}

.bg-sky-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(125 211 252 / var(--tw-bg-opacity) );
}

.bg-\[\#edf1f3\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 241 243 / var(--tw-bg-opacity) );
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity) );
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity) );
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-\[\#2b5b74\] {
  fill: #2b5b74;
}

.fill-\[\#C04184\] {
  fill: #c04184;
}

.fill-primary-200 {
  fill: #35697d;
}

.fill-\[\#DCDCDC\] {
  fill: #dcdcdc;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-3 {
  padding: .75rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-0 {
  padding: 0;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pr-96 {
  padding-right: 24rem;
}

.pt-36 {
  padding-top: 9rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-16 {
  padding-top: 4rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-extrabold {
  font-weight: 800;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.leading-4 {
  line-height: 1rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-\[80px\] {
  line-height: 80px;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-grey-800 {
  --tw-text-opacity: 1;
  color: rgb(64 77 86 / var(--tw-text-opacity) );
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.text-primary-400 {
  --tw-text-opacity: 1;
  color: rgb(126 159 172 / var(--tw-text-opacity) );
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity) );
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.text-cta-200 {
  --tw-text-opacity: 1;
  color: rgb(192 65 132 / var(--tw-text-opacity) );
}

.text-primary-200 {
  --tw-text-opacity: 1;
  color: rgb(53 105 125 / var(--tw-text-opacity) );
}

.text-primary-100 {
  --tw-text-opacity: 1;
  color: rgb(20 53 69 / var(--tw-text-opacity) );
}

.text-primary-300 {
  --tw-text-opacity: 1;
  color: rgb(90 133 150 / var(--tw-text-opacity) );
}

.text-teal-300 {
  --tw-text-opacity: 1;
  color: rgb(0 167 173 / var(--tw-text-opacity) );
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity) );
}

.text-error {
  --tw-text-opacity: 1;
  color: rgb(234 65 96 / var(--tw-text-opacity) );
}

.text-grey-400 {
  --tw-text-opacity: 1;
  color: rgb(237 241 243 / var(--tw-text-opacity) );
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(20 53 69 / var(--tw-text-opacity) );
}

.text-\[\#35697D\] {
  --tw-text-opacity: 1;
  color: rgb(53 105 125 / var(--tw-text-opacity) );
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity) );
}

.text-success {
  --tw-text-opacity: 1;
  color: rgb(0 132 137 / var(--tw-text-opacity) );
}

.text-warn {
  --tw-text-opacity: 1;
  color: rgb(249 151 30 / var(--tw-text-opacity) );
}

.text-cta-400 {
  --tw-text-opacity: 1;
  color: rgb(229 179 205 / var(--tw-text-opacity) );
}

.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity) );
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity) );
}

.text-\[\#c04184\] {
  --tw-text-opacity: 1;
  color: rgb(192 65 132 / var(--tw-text-opacity) );
}

.text-\[\#448394\] {
  --tw-text-opacity: 1;
  color: rgb(68 131 148 / var(--tw-text-opacity) );
}

.text-primary-500 {
  --tw-text-opacity: 1;
  color: rgb(207 219 224 / var(--tw-text-opacity) );
}

.text-\[\#f0e7e6\] {
  --tw-text-opacity: 1;
  color: rgb(240 231 230 / var(--tw-text-opacity) );
}

.text-cta-100 {
  --tw-text-opacity: 1;
  color: rgb(157 19 92 / var(--tw-text-opacity) );
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity) );
}

.text-\[\#7E9FAC\] {
  --tw-text-opacity: 1;
  color: rgb(126 159 172 / var(--tw-text-opacity) );
}

.text-\[\#C04184\] {
  --tw-text-opacity: 1;
  color: rgb(192 65 132 / var(--tw-text-opacity) );
}

.text-\[\#70757a\] {
  --tw-text-opacity: 1;
  color: rgb(112 117 122 / var(--tw-text-opacity) );
}

.text-\[\#41687b\] {
  --tw-text-opacity: 1;
  color: rgb(65 104 123 / var(--tw-text-opacity) );
}

.opacity-100 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: .25;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(20 53 69 / var(--tw-ring-opacity) );
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.masonry-grid {
  width: auto;
  margin-left: -30px;
  display: flex;
}

.masonry-grid_column {
  background-clip: padding-box;
  padding-left: 30px;
}

.tooltip:before, .tooltip:after {
  position: absolute;
  top: -.25rem;
  left: 50%;
  transform: translateX(-50%)translateY(-100%);
}

#discIncBanner {
  color: #fff;
  background-color: #ec6d62;
}

.tooltip:before {
  content: "Send Invite";
  color: #f5f5f5;
  text-align: center;
  height: 25px;
  width: max-content;
  background: gray;
  border-radius: .3rem;
  padding: .5rem;
}

.Toastify__toast--error {
  width: 382px;
  background: #fae1e2 !important;
  border-radius: 8px !important;
}

.even\:bg-gray-100:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity) );
}

.checked\:bg-cta-200:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(192 65 132 / var(--tw-bg-opacity) );
}

.checked\:text-cta-200:checked {
  --tw-text-opacity: 1;
  color: rgb(192 65 132 / var(--tw-text-opacity) );
}

.hover\:bg-teal-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 154 159 / var(--tw-bg-opacity) );
}

.hover\:bg-cta-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(218 111 168 / var(--tw-bg-opacity) );
}

.hover\:bg-\[\#7E9FAC\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(126 159 172 / var(--tw-bg-opacity) );
}

.hover\:bg-grey-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(237 241 243 / var(--tw-bg-opacity) );
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.hover\:text-pink-500:hover {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity) );
}

.hover\:text-\[\#7E9FAC\]:hover {
  --tw-text-opacity: 1;
  color: rgb(126 159 172 / var(--tw-text-opacity) );
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(20 53 69 / var(--tw-text-opacity) );
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-primary-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(20 53 69 / var(--tw-ring-opacity) );
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity) );
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-white:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity) );
}

.focus-visible\:ring-opacity-75:focus-visible {
  --tw-ring-opacity: .75;
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:ring-offset-black:focus-visible {
  --tw-ring-offset-color: #143545;
}

.active\:bg-teal-400:active {
  --tw-bg-opacity: 1;
  background-color: rgb(0 154 159 / var(--tw-bg-opacity) );
}

.disabled\:opacity-\[48\%\]:disabled {
  opacity: .48;
}

@media (prefers-color-scheme: dark) {
  .dark\:border-\[\#CFDBE0\] {
    --tw-border-opacity: 1;
    border-color: rgb(207 219 224 / var(--tw-border-opacity) );
  }

  .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity) );
  }

  .dark\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .dark\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity) );
  }
}

@media (min-width: 640px) {
  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-\[558px\] {
    width: 558px;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:duration-700 {
    transition-duration: .7s;
  }
}

@media (min-width: 768px) {
  .md\:fixed {
    position: fixed;
  }

  .md\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:max-w-xs {
    max-width: 20rem;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:pl-24 {
    padding-left: 6rem;
  }
}

@media (min-width: 1280px) {
  .xl\:mr-5 {
    margin-right: 1.25rem;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:flex-row {
    flex-direction: row;
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient( to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55 );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: Toastify__spin .65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ReactCrop {
  cursor: crosshair;
  max-width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ReactCrop:focus {
  outline: none;
}

.ReactCrop--disabled, .ReactCrop--locked {
  cursor: inherit;
}

.ReactCrop__image {
  max-width: 100%;
  touch-action: none;
  display: block;
}

.ReactCrop__crop-selection {
  box-sizing: border-box;
  cursor: move;
  touch-action: none;
  border: 1px solid;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  border-image-slice: 1;
  border-image-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 0 9999em #00000080;
}

.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}

.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #fff, 0 0 0 9999em #00000080;
}

.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}

.ReactCrop__rule-of-thirds-vt:before, .ReactCrop__rule-of-thirds-vt:after, .ReactCrop__rule-of-thirds-hz:before, .ReactCrop__rule-of-thirds-hz:after {
  content: "";
  background-color: #fff6;
  display: block;
  position: absolute;
}

.ReactCrop__rule-of-thirds-vt:before, .ReactCrop__rule-of-thirds-vt:after {
  width: 1px;
  height: 100%;
}

.ReactCrop__rule-of-thirds-vt:before {
  left: 33.3333%;
}

.ReactCrop__rule-of-thirds-vt:after {
  left: 66.6667%;
}

.ReactCrop__rule-of-thirds-hz:before, .ReactCrop__rule-of-thirds-hz:after {
  width: 100%;
  height: 1px;
}

.ReactCrop__rule-of-thirds-hz:before {
  top: 33.3333%;
}

.ReactCrop__rule-of-thirds-hz:after {
  top: 66.6667%;
}

.ReactCrop__drag-handle {
  position: absolute;
}

.ReactCrop__drag-handle:after {
  content: "";
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  background-color: #0003;
  border: 1px solid #ffffffb3;
  outline: 1px solid #0000;
  display: block;
  position: absolute;
}

.ReactCrop .ord-nw {
  cursor: nw-resize;
  margin-top: -5px;
  margin-left: -5px;
  top: 0;
  left: 0;
}

.ReactCrop .ord-nw:after {
  top: 0;
  left: 0;
}

.ReactCrop .ord-n {
  cursor: n-resize;
  margin-top: -5px;
  margin-left: -5px;
  top: 0;
  left: 50%;
}

.ReactCrop .ord-n:after {
  top: 0;
}

.ReactCrop .ord-ne {
  cursor: ne-resize;
  margin-top: -5px;
  margin-right: -5px;
  top: 0;
  right: 0;
}

.ReactCrop .ord-ne:after {
  top: 0;
  right: 0;
}

.ReactCrop .ord-e {
  cursor: e-resize;
  margin-top: -5px;
  margin-right: -5px;
  top: 50%;
  right: 0;
}

.ReactCrop .ord-e:after {
  right: 0;
}

.ReactCrop .ord-se {
  cursor: se-resize;
  margin-bottom: -5px;
  margin-right: -5px;
  bottom: 0;
  right: 0;
}

.ReactCrop .ord-se:after {
  bottom: 0;
  right: 0;
}

.ReactCrop .ord-s {
  cursor: s-resize;
  margin-bottom: -5px;
  margin-left: -5px;
  bottom: 0;
  left: 50%;
}

.ReactCrop .ord-s:after {
  bottom: 0;
}

.ReactCrop .ord-sw {
  cursor: sw-resize;
  margin-bottom: -5px;
  margin-left: -5px;
  bottom: 0;
  left: 0;
}

.ReactCrop .ord-sw:after {
  bottom: 0;
  left: 0;
}

.ReactCrop .ord-w {
  cursor: w-resize;
  margin-top: -5px;
  margin-left: -5px;
  top: 50%;
  left: 0;
}

.ReactCrop .ord-w:after {
  left: 0;
}

.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}

.ReactCrop__drag-bar {
  position: absolute;
}

.ReactCrop__drag-bar.ord-n {
  width: 100%;
  height: 6px;
  margin-top: -3px;
  top: 0;
  left: 0;
}

.ReactCrop__drag-bar.ord-e {
  width: 6px;
  height: 100%;
  margin-right: -3px;
  top: 0;
  right: 0;
}

.ReactCrop__drag-bar.ord-s {
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
  bottom: 0;
  left: 0;
}

.ReactCrop__drag-bar.ord-w {
  width: 6px;
  height: 100%;
  margin-left: -3px;
  top: 0;
  left: 0;
}

.ReactCrop--new-crop .ReactCrop__drag-bar, .ReactCrop--new-crop .ReactCrop__drag-handle, .ReactCrop--fixed-aspect .ReactCrop__drag-bar, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}

@media (pointer: coarse) {
  .ReactCrop .ord-n, .ReactCrop .ord-e, .ReactCrop .ord-s, .ReactCrop .ord-w {
    display: none;
  }

  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/*# sourceMappingURL=index.76e0e43e.css.map */
