@import "theorem-lib/src/index.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Sidebar */
@layer components {
    .bg-sidebar {
        @apply bg-primary-100
    }

    .top-acronym-container {
        @apply border-primary-300
    }

    .top-acronym {
        @apply text-primary-300
    }

    .menu-item {
        @apply
        text-primary-300
        ;
    }

    .menu-item-active {
        @apply
        text-primary-100
        ;
    }
}

.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.tooltip::before,
.tooltip::after {
    position: absolute;   
    top: -.25rem;
    left: 50%;    
    transform: translateX(-50%) translateY(-100%);
}

#discIncBanner {
    background-color: #EC6D62;
    color: white;
}

.tooltip::before {
    content: 'Send Invite';
    color: whitesmoke;
    padding: .5rem;
    border-radius: .3rem;
    text-align: center;
    height: 25px;
    width: max-content;
    background: gray;
}
.Toastify__toast--error {    
    border-radius: 8px !important;
    background: #FAE1E2 !important;
    width: 382px;
}



